'use client';

import TextInput from "@/components/forms/controls/TextInput";
import {useTranslation} from "@/i18n/client";
import {AllowedLanguages} from "@/types/generic";
import Button from "@/components/atoms/Button";
import {z} from "zod";
import {useZodForm} from "@/hooks/useZodForm";
import Form from "@/components/forms/Form";
import Checkbox from "@/components/forms/controls/Checkbox";
import {ofetch} from "ofetch";
import {newsletterPreferencesPaths} from "routes";
import useSWRMutation from "swr/mutation";
import {redirect, RedirectType} from "next/navigation";

interface NewsletterFormProps {
  locale: AllowedLanguages;
  location?: string;
  searchQuery?: string;
}

export const NewsletterForm = ({locale, location, searchQuery}: NewsletterFormProps) => {
  const {t} = useTranslation(locale);
  const schema = z.object({
    email: z.string().email(t("error.invalid_email")),
    first_name: z.string().min(1, t("error.required")).transform((val, ctx) => {
      return val.replace(/(<([^>]+)>)/gi, "");
    }),
    last_name: z.string().min(1, t("error.required")).transform((val, ctx) => {
      return val.replace(/(<([^>]+)>)/gi, "");
    }),
    privacy_statement: z.literal("true", {
      errorMap: () => ({message: t('error.privacy_statement_required')}),
    }),
  });

  const form = useZodForm({
    schema,
    defaultValues: {
      // @ts-expect-error Value should be true, but we set it as false so user has to change it
      privacy_statement: false
    }
  });

  async function subscribe(
    url: string,
    {
      arg,
    }: {
      arg: z.infer<typeof schema>;
    },
  ) {
    return await ofetch<string>(url, {
      method: "POST",
      body: {
        ...arg,
        locale,
      },
    });
  }

  const { data, trigger, isMutating, error } = useSWRMutation(
    "/api/newsletter",
    subscribe,
  );

  const handleSubmit = async (data: z.infer<typeof schema>) => {
    await trigger(data);
  };

  if (data?.key) {
    const url = new URL(`${process.env.NEXT_PUBLIC_BASE_URL}/${locale}/myvivaldis/${newsletterPreferencesPaths[locale]}`);
    url.searchParams.set("key", data.key);
    url.searchParams.set("location", location ?? "");
    url.searchParams.set("searchQuery", searchQuery ?? "");

    redirect(url.toString(), RedirectType.push);
  }

  return (
    <Form
      form={form}
      className="grid grid-cols-12 gap-x-6 xl:gap-x-10 gap-y-6"
      onSubmit={handleSubmit}
      noValidate={true}
    >
      <div className="col-span-12 md:col-span-6">
        <TextInput
          label={t('newsletter.first_name.label')}
          name="first_name"
          labelClasses="text-white"
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <TextInput
          label={t('newsletter.last_name.label')}
          name="last_name"
          labelClasses="text-white"
        />
      </div>
      <div className="col-span-12 md:col-span-6 2xl:col-span-8">
        <TextInput
          label={t('newsletter.email.label')}
          name="email"
          type="email"
          labelClasses="text-white"
        />
      </div>
      <div className="col-span-12 md:col-span-6 2xl:col-span-4 flex">
        <Button type="submit"
                className="btn-white btn-lg btn-outline mt-auto whitespace-nowrap border-2"
                hasLoader={true}
                isLoading={isMutating}
                disabled={isMutating}
        >{t('newsletter.submit')}</Button>
      </div>
      <div className="col-span-12">
        <Checkbox
          label={t("newsletter.privacyStatement.label")}
          name="privacy_statement"
          value="true"
          labelClasses="text-white"
        />
      </div>
    </Form>
  );
};

export default NewsletterForm;
