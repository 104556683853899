"use client";

import { useEffect } from "react";

import { usePageTranslationsPaths } from "@/store/pageTranslations";
import type { Translation } from "@/types/generic";

interface TranslatedPathsProps {
  translations: Translation[];
}

// The purpose of this component is to set the translated paths in the store so that the
// <LanguageSwitcher> component can use them to generate the correct hrefs.

const TranslatedPaths = ({ translations }: TranslatedPathsProps) => {
  const { setPageTranslationsPaths } = usePageTranslationsPaths();

  useEffect(() => {
    const translationPaths = translations?.filter(Boolean);
    setPageTranslationsPaths(translationPaths ?? []);
  }, [translations, setPageTranslationsPaths]);

  return null;
};

export default TranslatedPaths;
