"use client";

import { Fragment, useState } from "react";
import { usePathname } from "next/navigation";
import { Dialog, Transition } from "@headlessui/react";
import {
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton, FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

import Icon from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface ShareProps {
  className?: string;
  title: string;
  url?: string;
}

export const Share = ({ title, url, className }: ShareProps) => {
  const [open, setOpen] = useState(false);
  const pathname = usePathname();

  if (!url) {
    url = `${process.env.NEXT_PUBLIC_BASE_URL}${pathname}`;
  }

  // const facebookAppId = process.env.FACEBOOK_CLIENT_ID;

  const shareButtonProps = {
    title,
    url,
    className: "text-[10px] border-2 group hover:text-green transition",
    resetButtonStyle: false,
  };
  const shareIconProps = {
    size: 56,
    bgStyle: { fill: "none" },
    className:
      "!border !border-white rounded-sm mb-1 fill-current group-hover:!border-green transition",
    iconFillColor: "#333",
  };

  const onClickShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title,
          url,
        })
        .catch(console.error);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <button
        onClick={onClickShare}
        className={cn(
          "relative z-10 inline-flex aspect-square w-12 items-center justify-center rounded-full border-2 border-white/50 text-white transition-colors hover:border-black hover:text-black lg:w-16",
          className,
        )}
        type="button"
      >
        <Icon name="share" className="h-5 w-5 text-current" />
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-x-0 bottom-0 flex max-h-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-100"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen">
                    <div className="flex w-full items-center justify-center gap-3 bg-white p-3 shadow-xl">
                      <WhatsappShareButton {...shareButtonProps}>
                        <WhatsappIcon {...shareIconProps} />
                        <span>WhatsApp</span>
                      </WhatsappShareButton>

                      {/*{facebookAppId && (*/}
                      {/*  <FacebookMessengerShareButton appId={facebookAppId} {...shareButtonProps}>*/}
                      {/*    <FacebookMessengerIcon {...shareIconProps} />*/}
                      {/*    <span>Messenger</span>*/}
                      {/*  </FacebookMessengerShareButton>*/}
                      {/*)}*/}

                      <FacebookShareButton {...shareButtonProps}>
                        <FacebookIcon {...shareIconProps} />
                        <span>Facebook</span>
                      </FacebookShareButton>

                      <LinkedinShareButton {...shareButtonProps}>
                        <LinkedinIcon {...shareIconProps} />
                        <span>LinkedIn</span>
                      </LinkedinShareButton>

                      {/*<CopyShareButton {...shareButtonProps}>*/}
                      {/*  <LinkIcon*/}
                      {/*    className={*/}
                      {/*      "fill-gray group-hover:!border-green mb-1 h-14 w-14 rounded-sm !border !border-gray-500 p-2.5 transition"*/}
                      {/*    }*/}
                      {/*  />*/}
                      {/*  <span>Copy link</span>*/}
                      {/*</CopyShareButton>*/}

                      <EmailShareButton {...shareButtonProps}>
                        <Icon
                          name="paperplane"
                          className="mb-1 h-14 w-14 rounded-sm !border !border-gray-500 p-2.5 text-black transition group-hover:!border-green"
                        />
                        <span>Email</span>
                      </EmailShareButton>

                      {/*<TwitterShareButton {...shareButtonProps}>*/}
                      {/*  <TwitterIcon {...shareIconProps} />*/}
                      {/*  <span>Twitter</span>*/}
                      {/*</TwitterShareButton>*/}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
