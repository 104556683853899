import Icon, { Icons } from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface BadgeProps {
  children: React.ReactNode;
  className?: string;
  color: "brown" | "orange" | "green" | "white";
  icon?: Icons;
  iconClassName?: string;
}

const Badge = ({
  children,
  className,
  color,
  icon,
  iconClassName,
}: BadgeProps) => {
  const colorClasses = {
    orange: "bg-orange",
    green: "bg-green",
    white: "bg-white border text-black-500",
    brown: "bg-brown",
  };

  return (
    <span
      className={cn(
        "text-ellipsis overflow-hidden inline-flex items-center gap-2 rounded-[36px] px-4 py-1 text-white whitespace-nowrap",
        colorClasses[color],
        className,
      )}
    >
      {icon && <Icon name={icon} className={cn("h-4 w-4", iconClassName)} />}
      {children}
    </span>
  );
};

export default Badge;
